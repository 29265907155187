import { calculateLogoWidth } from '@blissbook/lib/branding'
import { type EmailBranding, EmailBrandingContext } from '@blissbook/lib/email'
import { cx } from '@emotion/css'
import repeat from 'lodash/repeat'
import React, { type CSSProperties, type ReactNode, forwardRef } from 'react'
import { type CallToAction, CallToActionButtonLink } from './callToAction'
import { Image } from './image'
import { Link } from './link'
import { Paragraph } from './paragraph'
import { TableContainer } from './table'

// Un-brandable constants
const bodyWidth = 600
const borderRadius = 10
const footerFontSize = 12
const fontSize = 16
const spacing = 30

const previewTextRepeatCount = 108
const previewTextSpace = '&nbsp;&zwnj;'
const previewTextSpacing = repeat(previewTextSpace, previewTextRepeatCount)

export type EmailLayoutProps = {
  branding: EmailBranding
  children?: ReactNode
  callToAction?: CallToAction
  className?: string
  onLoadBanner?: () => void
  onLoadLogo?: () => void
  padding?: string
  showSentByBlissbook?: boolean
  style?: CSSProperties
}

export const EmailLayout = forwardRef<HTMLTableElement, EmailLayoutProps>(
  (
    {
      branding,
      callToAction,
      children,
      className,
      onLoadBanner,
      onLoadLogo,
      padding = `${spacing}px 0`,
      showSentByBlissbook,
      style,
      ...props
    },
    ref,
  ) => {
    const {
      backgroundColor,
      bannerImage,
      footerLinks,
      footerText,
      footerTextColor,
      hrColor,
      logoImage,
      textColor,
    } = branding

    return (
      <EmailBrandingContext.Provider value={branding}>
        {/* Render the email preview text: https://litmus.com/blog/the-little-known-preview-text-hack-you-may-want-to-use-in-every-email */}
        <>
          {callToAction && (
            <div style={{ display: 'none', maxHeight: 0, overflow: 'hidden' }}>
              {callToAction.text}
            </div>
          )}

          <div
            // biome-ignore lint/security/noDangerouslySetInnerHtml: filtered manually
            dangerouslySetInnerHTML={{ __html: previewTextSpacing }}
            style={{ display: 'none', maxHeight: 0, overflow: 'hidden' }}
          />
        </>

        <style
          // biome-ignore lint/security/noDangerouslySetInnerHtml: filtered manually
          dangerouslySetInnerHTML={{
            __html: `
          @media only screen and (max-width: ${bodyWidth}px) {
          .email-background {
            background-color: white !important;
          }

          .email-footer td {
            padding-top: 0 !important;
          }

          .email-footer p:first-child {
            border-top: 2px solid ${hrColor};
            padding-top: ${spacing}px;
          }
        }
      `,
          }}
        />

        <TableContainer
          {...props}
          className={cx('email-background', className)}
          padding={padding}
          ref={ref}
          style={{
            ...style,
            background: backgroundColor,
          }}
        >
          <TableContainer width={bodyWidth}>
            {logoImage && (
              <TableContainer padding={`0 0 ${spacing}px`}>
                <Image
                  alt='Logo'
                  image={logoImage}
                  onLoad={onLoadLogo}
                  role='presentation'
                  style={{ display: 'block' }}
                  width={calculateLogoWidth(logoImage, {
                    minWidth: 60,
                    maxWidth: 400,
                  })}
                />
              </TableContainer>
            )}

            {bannerImage && (
              <TableContainer>
                <Image
                  alt='Header Image'
                  image={bannerImage}
                  onLoad={onLoadBanner}
                  role='presentation'
                  style={{
                    borderTopLeftRadius: borderRadius,
                    borderTopRightRadius: borderRadius,
                    backgroundColor: 'white',
                    display: 'block',
                  }}
                  width={bodyWidth}
                />
              </TableContainer>
            )}

            <TableContainer
              className='email-foreground'
              padding={spacing}
              style={{
                background: 'white',
                borderBottomLeftRadius: borderRadius,
                borderBottomRightRadius: borderRadius,
                borderTopLeftRadius: bannerImage ? 0 : borderRadius,
                borderTopRightRadius: bannerImage ? 0 : borderRadius,
                color: textColor,
                fontSize,
              }}
            >
              {callToAction && (
                <CallToActionButtonLink {...callToAction} spacing={spacing} />
              )}

              <TableContainer align='left'>{children}</TableContainer>

              {showSentByBlissbook && (
                <TableContainer align='center'>
                  <Paragraph />
                  <Paragraph style={{ fontStyle: 'italic' }}>
                    This email was sent via Blissbook
                  </Paragraph>
                </TableContainer>
              )}
            </TableContainer>

            {footerText && (
              <TableContainer
                align='left'
                className='email-footer'
                padding={`${spacing / 2}px ${spacing}px 0`}
                style={{
                  color: footerTextColor,
                  fontSize: footerFontSize,
                }}
              >
                {React.isValidElement(footerText) ? (
                  footerText
                ) : (
                  <Paragraph>{footerText}</Paragraph>
                )}
              </TableContainer>
            )}

            {footerLinks?.length > 0 &&
              footerLinks.map((link, index) => (
                <TableContainer
                  key={link.url + index}
                  align='left'
                  className='email-footer'
                  padding={`${spacing / 2}px ${spacing}px 0`}
                  style={{
                    color: footerTextColor,
                    fontSize: footerFontSize,
                  }}
                >
                  <Link href={link.url}>{link.text}</Link>
                </TableContainer>
              ))}
          </TableContainer>
        </TableContainer>
      </EmailBrandingContext.Provider>
    )
  },
)
