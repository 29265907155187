import { useEmailBrandingContext } from '@blissbook/lib/email'
import React from 'react'
import { type CSSProperties, forwardRef } from 'react'

export type TableProps = React.TableHTMLAttributes<HTMLTableElement> & {
  style?: CSSProperties
}

export const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ style, ...props }, ref) => {
    const { bodyFont } = useEmailBrandingContext()
    return (
      <table
        {...props}
        cellPadding='0'
        cellSpacing='0'
        ref={ref}
        style={{
          fontFamily: bodyFont,
          ...style,
        }}
      />
    )
  },
)

export type TableContainerProps =
  React.TableHTMLAttributes<HTMLTableElement> & {
    align?: 'center' | 'left' | 'right'
    padding?: number | string | undefined
    width?: number | string | undefined
    valign?: 'bottom' | 'middle' | 'top'
  }

export const TableContainer = forwardRef<HTMLTableElement, TableContainerProps>(
  (
    {
      align = 'center',
      children,
      padding,
      width = '100%',
      valign = 'top',
      ...props
    },
    ref,
  ) => {
    const { bodyFont } = useEmailBrandingContext()
    return (
      <Table {...props} ref={ref} width={width}>
        <tbody>
          <tr>
            <td
              align={align}
              style={{
                fontFamily: bodyFont,
                padding,
              }}
              valign={valign}
            >
              {children}
            </td>
          </tr>
        </tbody>
      </Table>
    )
  },
)
