import type { HandbookSectionDataModel } from '@blissbook/lib/models'
import type { ResolveAssetUrlFunction } from '@blissbook/lib/types'
import React, {
  type MutableRefObject,
  type ReactNode,
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react'

type BlissbookRendererHandbook = {
  branding: IBlissbookHandbookBranding
  id: number
  sectionsById: Map<number, HandbookSectionDataModel>
  timestamp: Date
  version: number
  wysiwyg?: string
}

type BlissbookRendererContextProps = {
  branding?: IBlissbookHandbookBranding
  resolveAssetUrl?: ResolveAssetUrlFunction
  handbookRef?: MutableRefObject<BlissbookRendererHandbook>
  person?: IPerson
  showExpressions?: boolean
  signature?: IHandbookSignature
}

const BlissbookRendererContext = createContext<BlissbookRendererContextProps>(
  {},
)

export const BlissbookRendererProvider = ({
  children,
  handbook,
  person,
  resolveAssetUrl,
  showExpressions,
  signature,
}: {
  children: ReactNode
  handbook: BlissbookRendererHandbook
  person?: IPerson
  resolveAssetUrl?: ResolveAssetUrlFunction
  showExpressions?: boolean
  signature?: IHandbookSignature
}) => {
  const handbookRef = useRef(handbook)
  handbookRef.current = handbook

  const { branding, id } = handbook
  const value = useMemo(
    () => ({
      branding,
      handbookRef,
      person,
      resolveAssetUrl,
      showExpressions,
      signature,
    }),
    [
      branding,
      id,
      handbookRef,
      person,
      resolveAssetUrl,
      showExpressions,
      signature,
    ],
  )

  return (
    <BlissbookRendererContext.Provider key={id} value={value}>
      {children}
    </BlissbookRendererContext.Provider>
  )
}

export const useBlissbookRendererContext = () =>
  useContext(BlissbookRendererContext)

export function resolveAssetUrl(url?: string) {
  const { resolveAssetUrl } = useBlissbookRendererContext()
  return resolveAssetUrl ? resolveAssetUrl(url) : url
}
