import type { Node } from '@blissbook/lib/document/types'
import React from 'react'
import { EmailContext, type EmailContextProps } from './context'
import { renderContent } from './nodes'

export type EmailVariables = Record<string, string | Date | JSX.Element>

export const renderEmailContent = (
  content: Node[],
  ctx?: EmailContextProps,
) => (
  <EmailContext.Provider value={ctx}>
    {renderContent(content)}
  </EmailContext.Provider>
)
