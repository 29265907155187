// List of provider types
export type IntegrationProviderId =
  | 'bamboohr'
  | 'rippling'
  | 'sftp'
  | 'mergedev'

// List of integration providers
export type IntegrationProvider = {
  // Unique identifier for the provider
  id: IntegrationProviderId
  // Name of the provider
  name: string
  // Allow multiple integration by this provider
  allowMultiple: boolean
  // Has people sync capability
  canSync: boolean
  // Whether or not this integration has been deprecated
  deprecated?: boolean
  // default install configuration
  defaultConfig?: any
  // Groups available on the people sync
  groups?: {
    key: string
    label: string
    description: string
  }[]
  // Hostnames
  hostnames?: string[]
  // Image icon
  icon?: string[]
  // Image url
  image?: string
  // Image aspect ratio
  imageAspectRatio?: number
  // Sync Timings
  syncTimings?: {
    label: string
    value: null | string
  }[]
  // Name of how the sync is done
  via: string
}

export const bamboohrProvider: IntegrationProvider = {
  id: 'bamboohr',
  name: 'BambooHR',
  allowMultiple: true,
  canSync: true,
  deprecated: true,
  hostnames: ['bamboohr.com', 'bamboohr.co.uk'],
  image: '/images/integrations/bamboohr.png',
  imageAspectRatio: 7,
  defaultConfig: {
    subdomain: '',
    hostname: 'bamboohr.com',
    email: '',
    password: '',
  },
  groups: [
    {
      key: 'state',
      label: 'State',
      description: '(e.g. NY, California, GA, NSW etc.)',
    },
    {
      key: 'country',
      label: 'Country',
      description: '(e.g. USA, UK, Australia, Germany, etc.)',
    },
    {
      key: 'location',
      label: 'Location',
      description: '(e.g. Atlanta, GA; London; Sydney, Australia; CA-SF; etc.)',
    },
    {
      key: 'division',
      label: 'Division',
      description: '(e.g. Manager, Remote, Europe, Asia-Pacific, etc.)',
    },
    {
      key: 'department',
      label: 'Department',
      description: '(e.g. Finance, HR, IT, Marketing, Operations, Sales, etc.)',
    },
    {
      key: 'employmentHistoryStatus',
      label: 'Employment Status',
      description: '(e.g. Full-Time, Internship, Temporary, Contractor, etc.)',
    },
    {
      key: 'exempt',
      label: 'Overtime Status',
      description: '(e.g. Exempt, Non-Exempt)',
    },
    {
      key: 'payType',
      label: 'Current Pay Type',
      description: '(e.g. Hourly, Salary, Commission, Contract, etc.)',
    },
  ],
  syncTimings: [
    {
      value: null,
      label: "As soon as they're added to BambooHR",
    },
    {
      value: 'hireDate',
      label: 'On their BambooHR hire date',
    },
  ],
  via: 'BambooHR Sync',
}

export const ripplingProvider: IntegrationProvider = {
  id: 'rippling',
  name: 'Rippling',
  allowMultiple: false,
  canSync: true,
  image: '/images/integrations/rippling-logo.png',
  imageAspectRatio: 10,
  groups: [
    {
      key: 'country',
      label: 'Country',
      description: '(e.g. US)',
    },
    {
      key: 'state',
      label: 'State',
      description: '(e.g. CA, NY, VA etc.)',
    },
    {
      key: 'city',
      label: 'City',
      description: '(e.g. Atlanta, GA; New York, NY; etc.)',
    },
    {
      key: 'department',
      label: 'Department',
      description: '(e.g. Finance, HR, IT, Marketing, Operations, Sales, etc.)',
    },
    {
      key: 'employmentType',
      label: 'Employment Type',
      description: '(e.g. Full-Time, Internship, Temporary, Contractor, etc.)',
    },
  ],
  via: 'Rippling',
}

export const sftpProvider: IntegrationProvider = {
  id: 'sftp',
  name: 'SFTP',
  allowMultiple: false,
  canSync: false,
  image: '/images/integrations/sftp.svg',
  imageAspectRatio: 47 / 32,
  via: 'SSH File Transfer',
}

export const mergeDevProvider: IntegrationProvider = {
  id: 'mergedev',
  name: 'Advanced HRIS Sync',
  allowMultiple: false,
  canSync: true,
  via: 'Merge.dev',
}

class IntegrationProviderArray extends Array<IntegrationProvider> {
  findById(id: string) {
    return this.find((p) => p.id === id)
  }
}

export const integrationProviders = new IntegrationProviderArray(
  bamboohrProvider,
  mergeDevProvider,
  ripplingProvider,
  sftpProvider,
)
