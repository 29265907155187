import { markViewersByType as htmlMarkViewersByType } from '@blissbook/lib/document/renderer/html'
import {
  type MarkViewerComponent,
  wrapMarksViewer,
} from '@blissbook/lib/document/renderer/marks'
import { LinkMarkViewer } from './link'

export const markViewersByType: Record<string, MarkViewerComponent> = {
  ...htmlMarkViewersByType,
  link: LinkMarkViewer,
}

export const MarksViewer = wrapMarksViewer(markViewersByType)
