import type { Mark } from '@blissbook/lib/document/types'
import React from 'react'
import type { CSSProperties } from 'react'

export type MarkViewerProps = {
  mark: Mark
  style?: CSSProperties
}

export type MarkViewerComponent = React.FC<MarkViewerProps>

export function wrapMarksViewer(
  markViewersByType: Record<string, MarkViewerComponent>,
  {
    skipColor,
  }: {
    skipColor?: boolean
  } = {},
) {
  return function MarksViewer({
    children,
    marks,
  }: {
    children: any
    marks?: Mark[]
  }) {
    if (!marks) return children

    // Render marks
    let style: CSSProperties
    for (let index = marks.length - 1; index >= 0; index--) {
      const mark = marks[index]
      const MarkViewer = markViewersByType[mark.type]
      if (mark.type === 'color') {
        if (skipColor) continue
        style ||= {}
        style.color = mark.attrs.color
      } else if (MarkViewer) {
        if (index === 0) {
          children = (
            <MarkViewer mark={mark} style={style}>
              {children}
            </MarkViewer>
          )
          style = undefined
        } else {
          children = <MarkViewer mark={mark}>{children}</MarkViewer>
        }
      } else {
        console.warn(`Unknown mark type ${mark.type}`)
      }
    }

    // Style not attached to anything
    if (style) {
      children = <span style={style}>{children}</span>
    }

    return children
  }
}
