import type { MarkViewerComponent } from '@blissbook/lib/document/renderer/marks'
import type { FontSizeMark } from '@blissbook/lib/document/types'
import React from 'react'

const largeFontSizePx = 54

export const FontSizeMarkViewer: MarkViewerComponent = ({
  children,
  mark,
  ...props
}) => {
  const fontSizeMark = mark as FontSizeMark
  const { fontSize } = fontSizeMark.attrs

  if (Number.parseInt(fontSize) >= largeFontSizePx) {
    children = <span className='bb-large-font'>{children}</span>
  }

  return (
    <span {...props} style={{ fontSize }}>
      {children}
    </span>
  )
}
