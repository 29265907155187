import {
  type MarkViewerComponent,
  wrapMarksViewer,
} from '@blissbook/lib/document/renderer/marks'
import { BoldMarkViewer } from './bold'
import { FontSizeMarkViewer } from './fontSize'
import { ItalicMarkViewer } from './italic'
import { LinkMarkViewer } from './link'
import { UnderlineMarkViewer } from './underline'

export const markViewersByType: Record<string, MarkViewerComponent> = {
  bold: BoldMarkViewer,
  fontSize: FontSizeMarkViewer,
  italic: ItalicMarkViewer,
  link: LinkMarkViewer,
  underline: UnderlineMarkViewer,
}

export const MarksViewer = wrapMarksViewer(markViewersByType)
