import { useEmailBrandingContext } from '@blissbook/lib/email'
import { cx } from '@emotion/css'
import React from 'react'
import type { CSSProperties } from 'react'
import { Table, TableContainer } from './table'

export type CallToAction = {
  /** Content to show after the call to action button */
  afterEl?: JSX.Element
  /** Content to show before the call to action button */
  beforeEl?: JSX.Element
  /** Call to action "button" URL */
  href?: string
  /** Call to action "button" text */
  text?: string
  /** Text editor override for editing */
  textEditor?: JSX.Element
}

export type CallToActionButtonLinkProps = CallToAction & {
  className?: string
  spacing: number
  style?: CSSProperties
}

export const CallToActionButtonLink: React.FC<CallToActionButtonLinkProps> = ({
  afterEl,
  beforeEl,
  className,
  href,
  spacing,
  style,
  text,
  textEditor,
  ...props
}) => {
  const { buttonColor, buttonTextColor } = useEmailBrandingContext()
  return (
    <TableContainer padding={`0 0 ${spacing}px`}>
      {beforeEl && (
        <TableContainer padding={`0 0 ${spacing / 2}px`}>
          {beforeEl}
        </TableContainer>
      )}

      <Table
        {...props}
        className={cx('email-button-link', className)}
        style={{
          backgroundColor: buttonColor,
          border: 'none',
          borderRadius: 6,
          ...style,
        }}
      >
        <tbody>
          <tr>
            <td
              align='center'
              valign='middle'
              style={{
                color: buttonTextColor,
                fontSize: 22,
                padding: '8px 30px',
              }}
            >
              {textEditor || (
                <a
                  href={href}
                  rel='noopener noreferrer'
                  target='_blank'
                  style={{
                    color: buttonTextColor,
                    textDecoration: 'none',
                  }}
                >
                  <span style={{ color: buttonTextColor }}>{text}</span>
                </a>
              )}
            </td>
          </tr>
        </tbody>
      </Table>

      {afterEl && (
        <TableContainer padding={`${spacing / 2}px 0 0`}>
          {afterEl}
        </TableContainer>
      )}
    </TableContainer>
  )
}
