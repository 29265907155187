import type { EmailBranding } from './branding'

// NOTE: This should match the styles applied in the renderer/components
export const getEmailCss = ({
  bodyFont,
  hrColor,
  linkColor,
}: EmailBranding) => `
  a {
    color: ${linkColor};
  }
  hr {
    border-top: 2px solid ${hrColor};
    border-right: 0 solid ${hrColor};
    border-bottom: 0 solid ${hrColor};
    border-left: 0 solid ${hrColor};
    margin: 1em 0;
  }
  ol {
    margin: 0;
  }
  p {
    font-family: ${bodyFont};
    margin: 0;
  }
  ul {
    margin: 0;
  }
  table {
    font-family: ${bodyFont};
  }
  td {
    font-family: ${bodyFont};
  }
`
