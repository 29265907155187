import type { EmailImage } from '@blissbook/lib/email'
import React from 'react'

export type ImageProps = Pick<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'alt' | 'onLoad' | 'role' | 'style' | 'width'
> & {
  image: EmailImage
}

export const Image: React.FC<ImageProps> = ({ image, ...props }) => (
  // biome-ignore lint/a11y/useAltText: passed in as props
  <img {...props} data-image-id={image.id} src={image.url} />
)
