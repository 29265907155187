import { type VariableNode, renderVariable } from '@blissbook/lib/document'
import React from 'react'
import type { NodeViewerComponent } from '.'
import { useEmailContext } from '../context'
import { MarksViewer } from '../marks'

export const VariableNodeViewer: NodeViewerComponent = ({ node }) => {
  const variable = node as VariableNode
  const { variables } = useEmailContext()

  return (
    <MarksViewer marks={variable.marks}>
      {renderVariable(variable.attrs, variables)}
    </MarksViewer>
  )
}
