import React from 'react'
import type { CSSProperties } from 'react'

export type ListProps = {
  className?: string
  style?: CSSProperties
}

export const BulletList: React.FC<ListProps> = ({ style, ...props }) => (
  <ul
    {...props}
    style={{
      margin: 0,
      ...style,
    }}
  />
)

export const OrderedList: React.FC<ListProps> = ({ style, ...props }) => (
  <ol
    {...props}
    style={{
      margin: 0,
      ...style,
    }}
  />
)
