export const tokenPlaceholder = '__TOKEN__'
export const tokenIdPlaceholder = '__TOKEN_ID__'
export const tokenPlaceholders = [tokenPlaceholder, tokenIdPlaceholder]

export const formatAuthUrl = (urlStr: string, isEmailAuthEnabled: boolean) => {
  const url = new URL(urlStr)
  if (isEmailAuthEnabled) {
    url.searchParams.set('token', tokenPlaceholder)
    url.searchParams.set('token_id', tokenIdPlaceholder)
  }
  return url.toString()
}

const tokenRegEx = new RegExp(tokenPlaceholder, 'g')
const tokenIdRegEx = new RegExp(tokenIdPlaceholder, 'g')

export const tokenizeHtml = (
  body: string,
  {
    token,
    tokenId,
  }: {
    token: string
    tokenId: string
  },
) => {
  return body.replace(tokenRegEx, token).replace(tokenIdRegEx, tokenId)
}
