import type { Node } from '@blissbook/lib/document/types'
import React from 'react'
import { BulletListNodeViewer } from './bulletList'
import { HorizontalRuleNodeViewer } from './horizontalRule'
import { ListItemNodeViewer } from './listItem'
import { OrderedListNodeViewer } from './orderedList'
import { ParagraphNodeViewer } from './paragraph'
import { TextNodeViewer } from './text'
import { VariableNodeViewer } from './variable'

export type NodeViewerProps = {
  className?: string
  node: Node
}

export type NodeViewerComponent = React.FC<NodeViewerProps>

export const NodeViewersByType: Record<string, NodeViewerComponent> = {
  bulletList: BulletListNodeViewer,
  horizontalRule: HorizontalRuleNodeViewer,
  listItem: ListItemNodeViewer,
  orderedList: OrderedListNodeViewer,
  paragraph: ParagraphNodeViewer,
  text: TextNodeViewer,
  variable: VariableNodeViewer,
}

export const NodeViewer: NodeViewerComponent = ({ node, ...props }) => {
  const NodeTypeViewer = NodeViewersByType[node.type]
  if (!NodeTypeViewer) {
    console.error(`Unknown NodeViewer type ${node.type}`)
    return null
  }

  return (
    <NodeTypeViewer {...props} node={node}>
      {'content' in node && renderContent(node.content)}
    </NodeTypeViewer>
  )
}

export const renderContent = (content: Node[]) => {
  return content.map((node, index) => <NodeViewer key={index} node={node} />)
}
