import { createContext, useContext } from 'react'

export type EmailVariables = Record<string, string | Date | JSX.Element>

export type EmailContextProps = {
  variables?: EmailVariables
}

export const EmailContext = createContext<EmailContextProps>({})

export const useEmailContext = () => useContext(EmailContext)
