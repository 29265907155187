import { useBlissbookRendererContext } from '@blissbook/lib/blissbook/renderer/context'
import {
  type ListItemNode,
  getAudienceExpressionFromNode,
  getIndentClassName,
} from '@blissbook/lib/document/types'
import { stringifyAudienceExpression } from '@blissbook/lib/expression'
import React from 'react'
import type { NodeViewerComponent } from '.'

export const ListItemNodeViewer: NodeViewerComponent = ({ children, node }) => {
  const { showExpressions } = useBlissbookRendererContext()
  const audienceExpression = getAudienceExpressionFromNode(node)
  const { attrs } = node as ListItemNode
  const { indent } = attrs || {}
  return (
    <li
      className={getIndentClassName(indent)}
      data-audience-expression={
        showExpressions && stringifyAudienceExpression(audienceExpression)
      }
    >
      {children}
    </li>
  )
}
