import {
  type VideoNode,
  getEmbedRenderProps,
} from '@blissbook/lib/document/types'
import React from 'react'
import type { NodeViewerComponent } from '.'

export const VideoNodeViewer: NodeViewerComponent = ({ node }) => {
  const { attrs } = node as VideoNode
  const { src } = attrs
  const embed = getEmbedRenderProps(attrs)
  return React.createElement('rw-video', {
    class: embed.className,
    src,
    style: embed.style,
  })
}
